"use client";
import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"  
import { Flex, Text } from '@radix-ui/themes';
import { Button } from '../ui/button';
import { Sarabun } from 'next/font/google';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from '../ui/input';
import { CodeIcon, PaperPlaneIcon, Pencil2Icon, PersonIcon } from '@radix-ui/react-icons';
import { Reservation } from '@prisma/client';
import { update } from '@/lib/managers/reservation';
import { toast } from 'sonner';
import { openAsBlob } from 'fs';

interface ReservationEditProps {
    trigger?: JSX.Element;
    children?: React.ReactNode;
    reservation: Reservation;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    mutate?: (data?: Reservation[] | Promise<Reservation[]> | undefined, shouldRevalidate?: boolean | undefined) => Promise<Reservation[] | undefined>;
    data?: Reservation[];
}

const formSchema = z.object({
    name: z.string().min(4).max(255, {
        message: 'ชื่อการจองต้องมีความยาวระหว่าง 4 ถึง 255 ตัวอักษร'
    }),
    reason: z.string().min(0).max(511, {
        message: 'รายละเอียดการจองต้องมีความยาวไม่เกิน 512 ตัวอักษร'
    }),
    person: z.number().int().min(1).positive({
        message: 'จำนวนคนต้องเป็นจำนวนเต็มบวก'
    }),
})

const sarabun = Sarabun({ subsets: ['latin'], weight: ['400', '500', '600', '700', '800'], display: 'swap' })

const ReservationEdit: React.FC<ReservationEditProps> = ({ reservation, children, setOpen, open, mutate, data }) => {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: reservation.name,
            reason: reservation.reason ?? 'ไม่มีรายละเอียด',
            person: reservation.person,
        },
        mode: 'all',
    })
    function onSubmit(values: z.infer<typeof formSchema>) {
        if (values.name === reservation.name && values.reason === reservation.reason && values.person === reservation.person) {
            toast.info('ไม่มีการเปลี่ยนแปลง')
            setOpen(false)
            return
        }
        update(reservation.id, values).then((result) => {
            if (result instanceof Error) {
                toast.error(result.message)
                return
            }
            if (mutate && data) {
                mutate(
                    data.map((r) => {
                        if (r.id === reservation.id) {
                            console.log(result);
                            return result
                        }
                        return r
                    }),
                    false
                )
                console.log('mutate', data.map((r) => {
                    if (r.id === reservation.id) {
                        return result
                    }
                    return r
                }))
            }
            toast.success('แก้ไขการจองสำเร็จ')
        })
        form.reset()
        setOpen(false)
    }
    return (
        <>
            <Dialog open={open} onOpenChange={
                (open) => {
                    if (!open) {
                        form.reset()
                    }
                }
            }>
                {children}
                <DialogContent className={sarabun.className} setOpen={setOpen}>
                    <DialogHeader>
                        <Text className='text-left text-xl 2xl:text-2xl leading-7' weight={'bold'}>
                            <DialogTitle>
                                <Text className='text-left text-xl 2xl:text-2xl leading-7' weight={'bold'}>
                                    แก้ไขการจอง
                                </Text>
                            </DialogTitle>
                        </Text>
                    </DialogHeader>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <>
                                        <FormItem>
                                            <FormLabel className='text-[var(--gray-12)]'>
                                                <Flex direction="row" >
                                                    <CodeIcon className='w-4 h-4 mr-2 my-auto' />
                                                    <Text className='text-base 2xl:text-lg' weight={'bold'}>
                                                        ชื่อการจอง
                                                    </Text>
                                                </Flex>
                                            </FormLabel>
                                            <FormControl>
                                                <Input placeholder="ชื่อการจอง" {...field} />
                                            </FormControl>
                                            <FormMessage className='text-sm 2xl:text-base text-[var(--ruby-10)]' />
                                        </FormItem>

                                    </>
                                )} 
                            />
                            <FormField
                                control={form.control}
                                name="reason"
                                render={({ field }) => (
                                    <>
                                        <FormItem>
                                            <FormLabel className='text-[var(--gray-12)]'>
                                                <Flex direction="row" >
                                                    <Pencil2Icon className='w-4 h-4 mr-2 my-auto' />
                                                    <Text className='text-base 2xl:text-lg' weight={'bold'}>
                                                        รายละเอียด
                                                    </Text>
                                                </Flex>
                                            </FormLabel>
                                            <FormControl>
                                                <Input placeholder="รายละเอียดการจอง" {...field} />
                                            </FormControl>
                                            <FormMessage className='text-sm 2xl:text-base text-[var(--ruby-10)]' />
                                        </FormItem>

                                    </>
                                )} 
                            />
                            <FormField
                                control={form.control}
                                name="person"
                                render={({ field }) => (
                                    <>
                                        <FormItem>
                                            <FormLabel className='text-[var(--gray-12)]'>
                                                <Flex direction="row" >
                                                    <PersonIcon className='w-4 h-4 mr-2 my-auto' />
                                                    <Text className='text-base 2xl:text-lg' weight={'bold'}>
                                                        จำนวนคน
                                                    </Text>
                                                </Flex>
                                            </FormLabel>
                                            <FormControl>
                                                <Input type='number' placeholder="จำนวนคน" {...field} />
                                            </FormControl>
                                            <FormMessage className='text-sm 2xl:text-base text-[var(--ruby-10)]' />
                                        </FormItem>

                                    </>
                                )} 
                            />
                            <DialogFooter>
                                <Button type="submit">
                                    บันทึก
                                </Button>
                            </DialogFooter>
                        </form>
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ReservationEdit;