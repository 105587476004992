'use client';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuPortal,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@/components/ui/hover-card"
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion"
import { CheckCircledIcon, CheckIcon, Cross1Icon, CrossCircledIcon, DotsVerticalIcon, EnterIcon, ExitIcon, PlusCircledIcon, QuestionMarkCircledIcon, QuoteIcon } from '@radix-ui/react-icons';
import { Separator, Flex, Button, IconButton, Text, Card, Blockquote } from '@radix-ui/themes'
import React, { ReactElement } from 'react';
import { Department, Reservation, Room, User } from '@prisma/client';
import { format } from 'date-fns';
import { th } from 'date-fns/locale/th';
import { Sarabun } from 'next/font/google';
import ReservationEdit from './ReservationEdit';
import { DialogTrigger } from '../ui/dialog';

type ReservationWithDepartment = Reservation & { department: Department, user: User, room: Room };

interface ReservationCardProps {
    reservation: ReservationWithDepartment;
    handleDelete?: (reservation: Reservation) => void;
    handleApproveOrReject?: (reservation: Reservation, status: 'APPROVED' | 'REJECTED') => void;
    session: any;
    mutate?: any;
    reservations?: Reservation[];
}

const sarabun = Sarabun({ subsets: ['latin'], weight: ['400', '500', '600', '700', '800'], display: 'swap' })

const statusStyle: { [key: string]: ReactElement } = {
    'PENDING': (
        <Text className='leading-7'>
            <QuestionMarkCircledIcon className='inline-block relative mx-auto mb-1' />
            {' '}รออนุมัติ
        </Text>
    ),
    'APPROVED': (
        <Text className='leading-7'>
            <CheckCircledIcon className='inline-block relative mx-auto mb-1' />
            {' '}อนุมัติแล้ว
        </Text>
    ),
    'REJECTED': (
        <Text className='leading-7'>
            <CrossCircledIcon className='inline-block relative mx-auto mb-1' />
            {' '}ไม่อนุมัติ
        </Text>
    )
};

const ReservationCard: React.FC<ReservationCardProps> = ({ reservation, handleDelete, handleApproveOrReject, session, reservations, mutate }) => {
    const [open, setOpen] = React.useState(false);
    return (
        <Card className={'mr-0 border-hidden outline-none rounded-sm p-2 w-[calc(100vw-1.5rem)] !h-max lg:w-[368px] sm:max-w-[368px]'} key={reservation.id}>
            <Flex className='float justify-start'>
                <div className='float justify-start max-w-[85%] overflow-clip whitespace-normal gap-2'>
                    <Text className='text-left inline-flex max-w-full text-lg text-wrap whitespace-normal leading-8 2xl:text-xl mr-2' weight={'bold'}>
                        {reservation.name} 
                    </Text>
                    <Badge
                        className={`text-sm ${
                            reservation.status === 'PENDING'
                                ? 'bg-[var(--indigo-7)] hover:bg-[var(--indigo-8)]'
                                : reservation.status === 'APPROVED'
                                ? 'bg-[var(--green-7)] hover:bg-[var(--green-8)]'
                                : 'bg-[var(--ruby-7)] hover:bg-[var(--ruby-8)]'
                        } text-[var(gray-12)]`}
                    >
                        {statusStyle[reservation.status]}
                    </Badge> 
                </div>
                {
                    handleDelete || handleApproveOrReject ? (
                        <ReservationEdit reservation={reservation} setOpen={setOpen} open={open} mutate={mutate} data={reservations}>
                            <DropdownMenu>
                                <DropdownMenuTrigger className='float-right absolute right-4'>
                                    <IconButton className='ml-auto' variant='soft' size='2' aria-label="Options" color='gray'>
                                        <DotsVerticalIcon />
                                    </IconButton>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className={sarabun.className}>
                                    {
                                        !(handleDelete && handleApproveOrReject) ? '' :  (
                                            <DialogTrigger asChild>
                                                <DropdownMenuItem onClick={
                                                    () => {
                                                        setOpen(true)
                                                    }
                                                }>
                                                    แก้ไข
                                                </DropdownMenuItem>
                                            </DialogTrigger>
                                        )
                                    }
                                    {
                                        handleDelete != undefined ? (
                                            <>
                                                <DropdownMenuItem onClick={() => handleDelete(reservation)}>
                                                    ลบ
                                                </DropdownMenuItem>
                                            </>
                                        ) : ''
                                    }
                                    {
                                        reservation.status === 'PENDING' && session.user.role !== 'USER' && handleApproveOrReject != undefined ? (
                                            <DropdownMenuSub>
                                                <DropdownMenuSubTrigger>
                                                    อัพเดตสถานะ
                                                </DropdownMenuSubTrigger>
                                                <DropdownMenuPortal>
                                                    <DropdownMenuSubContent>
                                                        <DropdownMenuItem onClick={() => handleApproveOrReject(reservation, 'APPROVED')}>
                                                            <CheckIcon className='inline w-4 h-4 mr-2 my-auto' />
                                                            อนุมัติ
                                                        </DropdownMenuItem>
                                                        <DropdownMenuItem onClick={() => handleApproveOrReject(reservation, 'REJECTED')}>
                                                            <Cross1Icon className='inline w-4 h-4 mr-2 my-auto' />
                                                            ปฏิเสธ
                                                        </DropdownMenuItem>
                                                    </DropdownMenuSubContent>
                                                </DropdownMenuPortal>
                                            </DropdownMenuSub>
                                        ) : ''
                                    }
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </ReservationEdit>
                    ) : ''
                }
            </Flex>
            <Separator my="3" size="4" />
            <Flex direction={'column'} gap={'1'}>
                <Flex className='w-full gap-3' direction={'row'}>
                    <Text className='text-left text-sm 2xl:text-base leading-7' weight={'bold'}>
                        ผู้จอง
                    </Text>
                    <Text className='text-left text-sm 2xl:text-base leading-7 !whitespace-pre-wrap'>
                        {reservation.user.name} {
                            reservation.department ?
                                ("(" + (reservation.department.name.startsWith("หมวด") ? reservation.department.name : 'กลุ่ม' + reservation.department.name) + ")") : ''
                        }
                    </Text>
                </Flex>
                <Flex className='w-full gap-3' direction={'row'}>
                    <Text className='text-left text-sm 2xl:text-base leading-7' weight={'bold'}>
                        ห้อง
                    </Text>
                    <HoverCard>
                        <HoverCardTrigger href='#'>
                            <Text className='text-left text-sm 2xl:text-base leading-7'>
                                {reservation.room.name}
                            </Text>
                        </HoverCardTrigger>
                        <HoverCardContent>
                            <Text className='text-left text-xs 2xl:text-sm leading-7' weight={'bold'}>
                                รายละเอียด -
                            </Text>
                            <Text className='text-left text-xs 2xl:text-sm leading-7'>
                                {' ' + reservation.room.description}
                            </Text>
                        </HoverCardContent>
                    </HoverCard>
                </Flex>
                <Flex className='w-full gap-3' direction={'row'}>
                    <Text className='text-left text-sm 2xl:text-base leading-7' weight={'bold'}>
                        จำนวนผู้ใช้งาน
                    </Text>
                    <Text className='text-left text-sm 2xl:text-base leading-7 text-wrap'>
                        {reservation.person} คน
                    </Text>
                </Flex>
                <Accordion type="single" collapsible className='border-none'>
                    <AccordionItem value="item-1">
                        <AccordionTrigger className='p-0 m-0 border-none'>
                            <Text className='text-left text-sm 2xl:text-base justify-start !leading-7' weight={'bold'}>
                                แสดงข้อมูลเพิ่มเติม
                            </Text>
                        </AccordionTrigger>
                        <AccordionContent className='border-none p-0 pt-1 gap-1'>
                            <Flex className='w-full gap-3' direction={'row'}>
                                <Text className='text-left text-sm 2xl:text-base justify-start !leading-7' weight={'bold'}>
                                    <EnterIcon className='inline mr-2 w-4 h-4 mb-1' />
                                    เริ่มต้น
                                </Text>
                                <Button
                                    disabled
                                    variant={"outline"}
                                    className={cn("w-fit pl-3 text-left font-normal h-7", "text-muted-foreground")}
                                >
                                    {format(reservation.start, "PPPp", { locale: th })}
                                </Button>
                            </Flex>
                            <Flex className='w-full gap-3' direction={'row'}>
                                <Text className='text-left text-sm 2xl:text-base justify-start !leading-7' weight={'bold'}>
                                    <ExitIcon className='inline mr-2 w-4 h-4 mb-1' />
                                    สิ้นสุด
                                </Text>
                                <Button
                                    disabled
                                    variant={"outline"}
                                    className={cn("w-fit pl-3 text-left font-normal h-7", "text-muted-foreground")}
                                >
                                    {format(reservation.end, "PPPp", { locale: th })}
                                </Button>
                            </Flex>
                            <Flex className='w-full gap-3' direction={'row'}>
                                <Text className='text-left text-sm 2xl:text-base justify-start !leading-7' weight={'bold'}>
                                    <PlusCircledIcon className='inline mr-2 w-4 h-4 mb-1' />
                                    จองเมื่อ
                                </Text>
                                <Button
                                    disabled
                                    variant={"outline"}
                                    className={cn("w-fit pl-3 text-left font-normal h-7", "text-muted-foreground")}
                                >
                                    {format(reservation.createdAt, "PPPp", { locale: th })}
                                </Button>
                            </Flex>
                            {
                                reservation.reason != null && reservation.reason != '' ? (
                                    <Flex className='w-full' direction={'column'}>
                                        <Text className='text-left text-sm 2xl:text-base leading-7' weight={'bold'}>
                                            <QuoteIcon className='inline mr-2 w-4 h-4 mb-1' />
                                            รายละเอียด
                                        </Text>
                                        <Blockquote>
                                            <Text className='text-left text-sm 2xl:text-base leading-7 text-wrap !whitespace-pre-wrap'>
                                                {reservation.reason}
                                            </Text>
                                        </Blockquote>
                                    </Flex>
                                ) : <></>
                            }
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </Flex>
        </Card>
    );
};

export default ReservationCard;