'use client';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { Department, Reservation, role, Room, User } from '@prisma/client';
import { CalendarIcon, CheckIcon, Cross1Icon, InfoCircledIcon, QuestionMarkIcon, ReaderIcon } from '@radix-ui/react-icons';
import { Separator, Container, Flex, Button, AlertDialog, Text, Card, Grid, IconButton } from '@radix-ui/themes'
import { format } from 'date-fns';
import { th } from 'date-fns/locale/th';
import { useSession } from 'next-auth/react';
import { useState } from 'react';
import useSWR from 'swr';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import * as ReservationManager from '@/lib/managers/reservation';
import { Skeleton } from '@/components/ui/skeleton';
import ReservationCard from '@/components/Dashboard/ReservationCard';
import Image from 'next/image';
import { preload } from 'react-dom';

const fetcher: any = (url: string) => fetch(url).then((res: any) => res.json());

preload('/empty.svg');

type ReservationWithDepartment = Reservation & { department: Department, user: User, room: Room };

export default function Status() {
    const { data: session } = useSession({
        required: true,
    });

    const [ selectedDate, setSelectedDate ] = useState<Date[] | undefined>(undefined);
    const [ historySelectedDate, setHistorySelectedDate ] = useState<Date[] | undefined>(undefined);

    const [ showOnlyPending, setShowOnlyPending ] = useState<boolean>();
    const [ showAsGrid, setShowAsGrid ] = useState<boolean>(true);

    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ dialogText, setDialogText ] = useState('PENDING');
    const [ dialogOptionText, setDialogOptionText ] = useState('OK');
    const [ dialogOptionAction, setDialogOptionAction ] = useState(() => () => {});
    
    const { data, error, mutate } = useSWR('/api/data/reservation', fetcher);
    if (error) {
        return <div>เกิดข้อผิดพลาดขึ้น กรุณาลองใหม่อีกครั้ง</div>
    }
    const reservations: ReservationWithDepartment[] = data;
    (reservations);

    function handleDelete(reservation: Reservation) {
        console.log('delete');
        setDialogText('คุณต้องการลบรายการจองนี้ใช่หรือไม่?');
        setDialogOptionText('ยืนยัน');
        setDialogOpen(true);
        setDialogOptionAction(() => () => {
            ReservationManager.deleteResdeervation(reservation.id).then((res) => {
                mutate(reservations.filter((e) => e.id !== reservation.id), false);
                setDialogOpen(false);
                console.log(res);
            });
        });
    }

    function handleApproveOrReject(reservation: Reservation, status: 'APPROVED' | 'REJECTED') {
        if (status === 'APPROVED' && reservations.filter((e) => e.status === 'APPROVED' && e.roomId === reservation.roomId).filter((e) => {
            return (new Date(e.start).getTime() <= new Date(reservation.end).getTime() && new Date(e.end).getTime() >= new Date(reservation.start).getTime());
        }).length > 0) {
            setDialogText('ไม่สามารถอนุมัติรายการจองนี้ได้ เนื่องจากมีการจองห้องที่ทับซ้อนกัน (ห้อง - ' + reservation.roomId + ')');
            setDialogOptionText('OK');
            setDialogOpen(true);
            setDialogOptionAction(() => () => {
                setDialogOpen(false);
            });
            return;
        }
        setDialogText(`คุณต้องการ${status === 'APPROVED' ? 'อนุมัติ' : 'ไม่อนุมัติ'}รายการจองนี้ใช่หรือไม่?`);
        setDialogOptionText('ยืนยัน');
        setDialogOpen(true);
        setDialogOptionAction(() => () => {
            ReservationManager.updateStatus(reservation.id, status).then((res) => {
                mutate(reservations.map((e) => {
                    if (e.id === reservation.id) {
                        e.status = status;
                    }
                    return e;
                }), false);
                setDialogOpen(false);
                console.log(res);
            });
        });
    }

    const currentReservations = session != null && reservations != null && reservations.length > 0 ? reservations
    .filter((e) => showOnlyPending ? e.status === 'PENDING' : true )
    .filter((e) => (
        new Date(e.end).getTime() >= new Date().getTime()
    )).filter((e) => (
        selectedDate != undefined && selectedDate.length > 0 ? selectedDate.find((d) => new Date(e.start).toDateString() === d.toDateString()) != undefined : true
    )).sort(
        (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime() || new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    ) : [];

    const historyReservations = session != null && reservations != null && reservations.length > 0 ? reservations
    .filter((e) => (
        new Date(e.end).getTime() < new Date().getTime()
    )).filter((e) => (
        historySelectedDate != undefined && historySelectedDate.length > 0 ? historySelectedDate.find((d) => new Date(e.start).toDateString() === d.toDateString()) != undefined : true
    )).sort(
        (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime() || new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    ) : [];
    
    return (
        <div className='mx-3'>
            {/* Status Dialog */}
            <AlertDialog.Root open={dialogOpen}>
                <AlertDialog.Content>
                    <AlertDialog.Title>
                        <InfoCircledIcon className="inline-block relative mb-[3px]" aria-hidden width={18} height={18} color='gray'/>
                        {' '}คำเตือน
                    </AlertDialog.Title>
                    <AlertDialog.Description size="2" mb="4">
                        {dialogText}
                    </AlertDialog.Description>
                    <Flex gap="3" mt="4" justify="end">
                        <AlertDialog.Cancel>
                            <Button className='transition-all duration-250 px-2 rounded-sm' onClick={() => setDialogOpen(false)}>
                                ยกเลิก
                            </Button>
                        </AlertDialog.Cancel>
                        {dialogOptionAction != undefined ? <AlertDialog.Cancel>
                            <Button onClick={() => dialogOptionAction == undefined ? setDialogOpen(false) : dialogOptionAction()} >
                                {dialogOptionText}
                            </Button>
                        </AlertDialog.Cancel> : <></>}
                    </Flex>
                </AlertDialog.Content>
            </AlertDialog.Root>
            {/* Main Content */}
            <Container className='w-full flex float overflow-clip'>
                {
                    session?.user.role && (session.user.role === "ADMIN" || session.user.role === "SUPERADMIN") ? (
                        <Grid align={'baseline'} columns={'4'}  flow={'column'} className='hidden md:grid mb-4 gap-3'>
                            <Card className='p-2 xl:p-6 gap-2'>
                                <Flex className='justify-between space-y-0 pb-2' gap={'3'} direction={'column'}>
                                    <Flex className='justify-between w-full'>
                                        <Text className="text-sm md:text-base 2xl:text-xl font-bold float-left">
                                            การจองทั้งหมด
                                        </Text>
                                        <ReaderIcon className="hidden md:block h-4 w-4 my-auto float-right text-muted-foreground" />
                                    </Flex>
                                    <Text className='text-left inline-flex text-base 2xl:text-lg' color='gray'>
                                        {' '}{
                                            reservations != null && session != null ? reservations.length + " รายการ" : (
                                                <Skeleton className='w-1/2 h-7' />
                                            )
                                        }
                                    </Text>
                                </Flex>
                            </Card>
                            <Card className='p-2 xl:p-6 gap-2'>
                                <Flex className='justify-between space-y-0 pb-2' gap={'3'} direction={'column'}>
                                    <Flex className='justify-between w-full'>
                                        <Text className="text-sm md:text-base 2xl:text-xl font-bold float-left">
                                            การจองที่อนุมัติ
                                        </Text>
                                        <CheckIcon className="hidden md:block h-4 w-4 my-auto float-right text-muted-foreground" />
                                    </Flex>
                                    <Text className='text-left inline-flex text-base 2xl:text-lg' color='gray'>
                                        {' '}{
                                            reservations != null && session != null ? reservations.filter((e) => e.status === 'APPROVED').length + " รายการ" : (
                                                <Skeleton className='w-1/2 h-7' />
                                            )
                                        }
                                    </Text>
                                </Flex>
                            </Card>

                            <Card className='p-2 xl:p-6 gap-2'>
                                <Flex className='justify-between space-y-0 pb-2' gap={'3'} direction={'column'}>
                                    <Flex className='justify-between w-full'>
                                        <Text className="text-sm md:text-base 2xl:text-xl font-bold float-left">
                                            การจองที่ไม่อนุมัติ
                                        </Text>
                                        <Cross1Icon className="hidden md:block h-4 w-4 my-auto float-right text-muted-foreground" />
                                    </Flex>
                                    <Text className='text-left inline-flex text-base 2xl:text-lg' color='gray'>
                                        {' '}{
                                            reservations != null && session != null ? reservations.filter((e) => e.status === 'REJECTED').length + " รายการ" : (
                                                <Skeleton className='w-1/2 h-7' />
                                            )
                                        }
                                    </Text>
                                </Flex>
                            </Card>
                            <Card className='p-2 xl:p-6 gap-2'>
                                <Flex className='justify-between space-y-0 pb-2' gap={'3'} direction={'column'}>
                                    <Flex className='justify-between w-full'>
                                        <Text className="text-sm md:text-base 2xl:text-xl font-bold float-left">
                                            การจองที่รออนุมัติ
                                        </Text>
                                        <QuestionMarkIcon className="h-4 w-4 text-muted-foreground" />
                                    </Flex>
                                    <Text className='text-left inline-flex text-base 2xl:text-lg' color='gray'>
                                        {' '}{
                                            reservations != null && session != null ? reservations.filter((e) => e.status === 'PENDING').length + " รายการ" : (
                                                <Skeleton className='w-1/2 h-7' />
                                            )
                                        }
                                    </Text>
                                </Flex>
                            </Card>
                        </Grid>
                    ) : null
                }
                <Text className='inline-flex text-xl 2xl:text-2xl text-start ps-3' weight={'bold'}>
                    {' '}{
                        session != null && session.user.role && !(session.user.role === role.STUDENT || session.user.role === role.TEACHER) ? 'การจองทั้งหมด' : 'การจองของคุณ'
                    }
                </Text>
                <Flex gap='3' className='hidden sm:inline-flex float-right' direction={'row'} >
                    <Button onClick={() => setShowOnlyPending(!showOnlyPending)} color='iris'>
                        {
                            !showOnlyPending ? 'แสดงเฉพาะที่รอดำเนินการ' : 'แสดงทั้งหมด'
                        }
                    </Button>
                    <IconButton onClick={() => setShowAsGrid(!showAsGrid)} color='gray'>
                        <ReaderIcon />
                    </IconButton>
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                            variant={"soft"}
                            color='gray'
                            className={cn(
                                "min-w-[240px] w-fit pl-3 text-left font-normal",
                                !selectedDate && "text-muted-foreground"
                            )}
                            disabled={reservations == null}
                            >
                            {selectedDate && selectedDate.length > 1 ? (
                                <span>
                                    {format(selectedDate.sort((a, b) => a.getTime() - b.getTime())[0], "PPP", {locale: th})} และอีก {selectedDate.length - 1} วัน
                                </span>
                            ) : selectedDate && selectedDate.length > 0 ?  (
                                format(selectedDate[0], "PPP", {locale: th})
                            ) : (
                                <span>
                                    ตัวกรองวันที่
                                </span>
                            )}
                                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                            mode="multiple"
                            selected={selectedDate as Date[]}
                            onSelect={(date) => {
                                setSelectedDate(date);
                            }}
                            disabled={(date) =>
                                date < new Date()
                            }
                            initialFocus
                            locale={th}
                        />
                        </PopoverContent>
                    </Popover>
                </Flex>
                <Separator my="3" size="4" />
                <ScrollArea className="w-full whitespace-nowrap rounded-md mb-4">
                    {
                        session != null && reservations != null && currentReservations.length == 0 ? (
                            <Flex className='justify-center w-full !h-[212px]'>
                                <Image className='w-full h-full my-auto mx-auto float-none' src='/empty.svg' width={75} height={75} alt='ไม่มีรายการจอง' priority  />
                            </Flex>
                        ) : (
                            <Flex className={"w-max p-0 pb-3" + (showAsGrid ? ' grid-cols-3 grid justify-between mx-auto gap-2 justify-items-center' : ' space-x-4')}>
                                {
                                    reservations == null || reservations == null ? 
                                        Array(3).fill(0).map((_, i) => (
                                            <Card key={i} className={'mr-0 border-hidden outline-none rounded-sm justify-around p-2 w-[calc(100vw-1.5rem)] !h-[212px] lg:w-[368px] sm:max-w-[368px]'}>
                                                <Flex direction={'column'} className='justify-around h-full'>
                                                    <Flex className='float justify-start'>
                                                        <Skeleton className='w-1/5 h-7' />
                                                    </Flex>
                                                    <Separator my="3" size="4" />
                                                    <Flex className='w-full gap-3' direction={'row'}>
                                                        <Skeleton className='w-1/2 h-7' />
                                                    </Flex>
                                                    <Flex className='w-full gap-3' direction={'row'}>
                                                        <Skeleton className='w-1/4 h-7' />
                                                    </Flex>
                                                    <Flex className='w-full gap-3' direction={'row'}>
                                                        <Skeleton className='w-1/3 h-7' />
                                                    </Flex>
                                                    <Skeleton className='w-full h-6' />
                                                </Flex>
                                            </Card>
                                        )) :
                                        currentReservations.map((reservation: ReservationWithDepartment) => {
                                            return (
                                               <ReservationCard handleApproveOrReject={handleApproveOrReject} handleDelete={handleDelete} reservation={reservation} key={reservation.id} session={session} reservations={reservations} mutate={mutate} />
                                            )
                                    })
                                }
                            </Flex>
                        )
                    }
                    <ScrollBar orientation="horizontal" />
                </ScrollArea>
                <Text className='text-left inline-flex text-xl 2xl:text-2xl ps-3' weight={'bold'}>
                    {' '}ประวัติการจอง
                </Text>
                <Flex gap='3' className='hidden sm:inline-flex float-right' direction={'row'} >
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={"soft"}
                                color='gray'
                                className={cn(
                                    "min-w-[240px] w-fit pl-3 text-left font-normal",
                                    !historySelectedDate && "text-muted-foreground"
                                )}
                                disabled={reservations == null}
                            >
                            {historySelectedDate && historySelectedDate.length > 1 ? (
                                <span>
                                    {format(historySelectedDate.sort((a, b) => a.getTime() - b.getTime())[0], "PPP", {locale: th})} และอีก {historySelectedDate.length - 1} วัน
                                </span>
                            ) : historySelectedDate && historySelectedDate.length > 0 ?  (
                                format(historySelectedDate[0], "PPP", {locale: th})
                            ) : (
                                <span>
                                    ตัวกรองวันที่
                                </span>
                            )}
                                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                            mode="multiple"
                            selected={historySelectedDate as Date[]}
                            onSelect={(date) => {
                                setHistorySelectedDate(date);
                            }}
                            initialFocus
                            disabled={(date) =>
                                date >= new Date()
                            }
                            locale={th}
                        />
                        </PopoverContent>
                    </Popover>
                </Flex>
                <Separator my="3" size="4" />
                <ScrollArea className="w-full whitespace-nowrap rounded-md mb-4">
                    {
                        session != null && reservations != null && historyReservations.length <= 0 ? (
                            <Flex className='justify-center w-full !h-[212px]'>
                                <Image className='w-full h-full my-auto mx-auto float-none' src='/empty.svg' width={75} height={75} alt='ไม่มีรายการจอง' priority  />
                            </Flex>
                        ) : (
                            <Flex className="w-max space-x-4 p-0 pb-3">
                                {
                                    reservations == null || reservations == null ? 
                                        Array(3).fill(0).map((_, i) => (
                                            <Card key={i} className={'mr-0 border-hidden outline-none rounded-sm justify-around p-2 w-[calc(100vw-1.5rem)] !h-[212px] lg:w-[368px] sm:max-w-[368px]'}>
                                                <Flex direction={'column'} className='justify-around h-full'>
                                                    <Flex className='float justify-start'>
                                                        <Skeleton className='w-1/5 h-7' />
                                                    </Flex>
                                                    <Separator my="3" size="4" />
                                                    <Flex className='w-full gap-3' direction={'row'}>
                                                        <Skeleton className='w-1/2 h-7' />
                                                    </Flex>
                                                    <Flex className='w-full gap-3' direction={'row'}>
                                                        <Skeleton className='w-1/4 h-7' />
                                                    </Flex>
                                                    <Flex className='w-full gap-3' direction={'row'}>
                                                        <Skeleton className='w-1/3 h-7' />
                                                    </Flex>
                                                    <Skeleton className='w-full h-6' />
                                                </Flex>
                                            </Card>
                                        )) :
                                        historyReservations.map((reservation: ReservationWithDepartment) => {
                                            return (
                                                <ReservationCard handleDelete={handleDelete} reservation={reservation} key={reservation.id} session={session} reservations={reservations} mutate={mutate} />
                                            )
                                        })
                                }
                            </Flex>
                        )
                    }
                    <ScrollBar orientation="horizontal" />
                </ScrollArea>
            </Container>
        </div>
    );
}